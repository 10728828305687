import { Typography } from '@maersktankersdigital/web-components'
import { IVesselsReadResponse } from '~api/vessels/read'
import { InfoLabel } from '~components/atoms/info-label/info-label'
import { RedeliveryLabel } from '~pages/pages-behind-login/vessel-page/redelivery-label/redelivery-label'
import { HeaderActions } from '~pages/pages-behind-login/vessel-page/vessel-header/header-actions/header-actions'
import { HeaderDetails } from '~pages/pages-behind-login/vessel-page/vessel-header/header-details/header-details'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { TrackFieldValue } from '~pages/pages-behind-login/vessels-page/vessels-page-popup/vessels-page-popup.config'

import Wrapper from '~components/layout/wrapper/wrapper'
import {
  StyledHeaderLabelsContainer,
  StyledHeadlineAndActionsContainer,
  StyledPageHeader,
  StyledVesselPageHeaderInner,
} from './vessel-header.styles'

export function VesselHeader({
  vessel,
  pageConfig,
}: {
  pageConfig: any | null
  vessel: IVesselsReadResponse
}) {
  const state = useVesselPageState()
  const isCommercialManagement =
    vessel.status === TrackFieldValue.COMMERCIAL_MANAGEMENT

  return (
    <StyledPageHeader>
      <Wrapper>
        <StyledVesselPageHeaderInner>
          <div>
            <StyledHeadlineAndActionsContainer>
              <Typography variant="headline1">{vessel.vesselName}</Typography>
              <HeaderActions vesselData={vessel} />
            </StyledHeadlineAndActionsContainer>
            {isCommercialManagement && (
              <Typography variant="menu">Commercial Management</Typography>
            )}

            <StyledHeaderLabelsContainer>
              <RedeliveryLabel />
              {state.isVesselArchived && (
                <InfoLabel text="archived" variant="VesselDashboard" />
              )}
            </StyledHeaderLabelsContainer>
          </div>

          <HeaderDetails vessel={vessel} />
        </StyledVesselPageHeaderInner>

        {pageConfig?.header?.buttonComponentFn?.(state)}
      </Wrapper>
    </StyledPageHeader>
  )
}

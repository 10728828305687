import { addMinutes, format, isBefore } from 'date-fns'

// Filename to be changed after removing the original `dates.ts` file
export function toDateTimeFormat(dateString: string) {
  const date = new Date(dateString)
  const locale = 'da-DK'

  return `${toDateFormat(dateString)}, ${date.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  })}`
}

export function toDateFormat(dateString: string | Date) {
  const date = new Date(dateString)
  const locale = 'da-DK'

  return `${date.toLocaleDateString(locale).replaceAll('.', '-')}`
}

// Returns the difference of months in the future, everything past the startDate returns 0
export function getMonthDiff(startDate: Date, endDate: Date) {
  const yearsDifference = endDate.getFullYear() - startDate.getFullYear()
  const monthsDifference = endDate.getMonth() - startDate.getMonth()
  const daysDifference = endDate.getDate() - startDate.getDate()

  const monthCorrection = daysDifference > 0 ? 1 : 0

  return yearsDifference * 12 + monthsDifference + monthCorrection
}

export function isDateExpired(date: string) {
  const parsedDate = new Date(date)
  return isBefore(parsedDate, new Date())
}

export function forceDateToZuluTimezone(date: Date) {
  const helperDate = new Date()
  return addMinutes(date, -helperDate.getTimezoneOffset())
}

export const formatDate = (
  date: string | Date,
  pattern: string = 'EEEE, MMMM d yyyy',
): string => {
  if (!date) return ''
  if (date instanceof Date) return format(date, pattern)
  return format(new Date(date), pattern)
}

const numberFormatter = new Intl.NumberFormat()

import { Sire } from '~components/organisms/dashboard/dashboard.types'
import { FALLBACK_STRING_DASH } from '~constants/global-strings'
import { ITableRowData } from '~hooks/queries/vessels/data/types/tabs-read'
import { VesselDashboardTab } from '~hooks/queries/vessels/data/types/vessel-dashboard'

export function getUpcomingCdiDate(cdis: ITableRowData[] | undefined) {
  if (!cdis || cdis.length === 0) return

  return cdis.find((cdi) => !cdi.inspectionCompleted)?.inspectionDate
}

type CommonType = {
  productCodes: string
  cargoGrades: string
}

export function getLastThreeVoyages<T extends CommonType>(
  voyages: T[] | undefined,
): T[] | undefined {
  if (!voyages || voyages.length === 0) return

  return voyages
    .filter(
      ({ productCodes, cargoGrades }) =>
        productCodes.length > 0 || cargoGrades.length > 0,
    )
    .slice(0, 3)
}

export function getLastSire(sires: Sire[] | undefined) {
  if (!sires || sires.length === 0) return

  return sires.find((sire) => sire.inspectionCompleted)
}

export function getUpcomingSireDate(sires: Sire[] | undefined) {
  if (!sires || sires.length === 0) return

  return sires?.find((sire) => !sire.inspectionCompleted)?.inspectionDate
}

export function getCommercialOperator(
  dashboardData: VesselDashboardTab | undefined,
) {
  const q88CommercialOperator =
    getQ88OwnersDetails(dashboardData).commercialOperator

  return (
    dashboardData?.commercialOperator ?? {
      address: q88CommercialOperator?.Address1,
      company: q88CommercialOperator?.Company,
      email: q88CommercialOperator?.Email,
      phone: q88CommercialOperator?.Phone,
      website: q88CommercialOperator?.Website,
    }
  )
}

export function getOwnersDetails(
  dashboardData: VesselDashboardTab | undefined,
) {
  return {
    registeredOwner:
      dashboardData?.Ownership_Operation?.RegisteredOwner?.ContactDetail,
    commercialOperator:
      dashboardData?.Ownership_Operation?.CommericalOperator?.ContactDetail,
    disponentOwner:
      dashboardData?.Ownership_Operation?.DisponentOwner?.ContactDetail,
  }
}

export function getDisponentOwner(
  dashboardData: VesselDashboardTab | undefined,
) {
  const q88DisponentOwner = getQ88OwnersDetails(dashboardData).disponentOwner

  return (
    dashboardData?.disponentOwner ?? {
      address: q88DisponentOwner?.Address1,
      company: q88DisponentOwner?.Company,
      email: q88DisponentOwner?.Email,
      phone: q88DisponentOwner?.Phone,
      website: q88DisponentOwner?.Website,
    }
  )
}

export function getQ88OwnersDetails(
  dashboardData: VesselDashboardTab | undefined,
) {
  const ownersDetails = getOwnersDetails(dashboardData)

  return {
    registeredOwner: ownersDetails?.registeredOwner,
    commercialOperator: ownersDetails?.commercialOperator,
    disponentOwner: ownersDetails?.disponentOwner,
  }
}

export function evaluateData(data: string[] | undefined | null): string {
  if (!data || data.length === 0) return FALLBACK_STRING_DASH
  if (data.some((tank) => tank === null)) return FALLBACK_STRING_DASH
  if (data.every((tank) => tank.toLowerCase() === 'yes')) return 'Yes'

  return 'No'
}

import { ExactScopeName } from '~hooks/permissions/use-has-scope'

export const dummyUserVt = {
  ToSAcceptedDate: '2023-01-01T00:00:00Z',
  activePools: ['city'],
  active: true,
  department: 'Engineering',
  email: 'johndoe@example.com',
  favoriteVesselsNames: [
    {
      vesselName: 'Vessel1',
      vesselId: 'vessel1-id',
    },
    {
      vesselName: 'Vessel2',
      vesselId: 'vessel2-id',
    },
  ],
  name: 'John Doe',
  phoneNumber: '+1234567890',
  roles: [
    {
      roleName: 'Admin',
      roleId: 'admin-id',
    },
    {
      roleName: 'User',
      roleId: 'user-id',
    },
  ],
  secondaryPhoneNumber: '+0987654321',
  settings: {
    theme: 'dark',
    notifications: true,
  },
  statusCode: 'active',
  sub: '1234567890',
  scope: [ExactScopeName.maerskTankersUser],
  isInternal: true,
  poolPartnerKeys: ['partner1', 'partner2'],
}

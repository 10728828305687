import {
  CurrentVoyageStatus,
  GetVesselsResItem,
  ReStatus,
  TcinStatus,
} from '~hooks/queries/vessels/use-get-vessels'

export const dummyVessel: GetVesselsResItem = {
  imoNumber: '1234567',
  pool: 'Alpha Pool',
  poolPartner: 'Partner A',
  targetDate: new Date('2023-12-31'),
  status: ReStatus.CommercialManagement,
  vesselName: 'Vessel A',
  progress: 75,
  tcin: [
    {
      deliveryDateLocal: new Date('2023-01-01'),
      redeliveryDateLocal: new Date('2023-06-01'),
      counterpartyShortName: 'Counterparty A',
      status: TcinStatus.Delivered,
      vesselCode: 'V123',
    },
  ],
  currentVoyage: {
    status: CurrentVoyageStatus.Commenced,
    chartererShortName: 'Charterer A',
    department: 'Dept A',
    ballastPort: 'Port A',
    loadPort: 'Port B',
    dischargePort: 'Port C',
  },
  vesselId: 'V123456',
  vesselCode: 'V123',
  poolPartnerName: 'Partner A',
  deliveryDate: new Date('2023-01-01'),
  redeliveryDate: new Date('2023-06-01'),
  onboardedDate: new Date('2023-01-01'),
  archivedAt: new Date('2023-07-01'),
  archivedReason: 'Reason A',
}
